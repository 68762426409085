<template>
  <div>
    <div class="container1">
      <div class='container1text'>Puede compartir por los siguientes medios y redes:</div>
      <div class='container1links'>
        <ShareNetwork v-for="network in networks" :network="network.network" :key="network.network" :style="{backgroundColor: network.color}" :url="sharing.url" :title="sharing.title" :description="sharing.description" :quote="sharing.quote" :hashtags="sharing.hashtags" :twitterUser="sharing.twitterUser">
          <i :class='network.classicon'>
            <feather-icon :icon="network.icon"></feather-icon>
          </i>
          <span>{{ network.name }}</span>
        </ShareNetwork>
        <a class="share-network-copiar" v-clipboard:copy="sharing.url" v-clipboard:error="onError">
          <i class='iconfeather'>
            <feather-icon icon="CopyIcon"></feather-icon>
          </i>
          <span>Copiar</span>
        </a>
      </div>
    </div>
    <b-table class="guest-table" :items="items" :fields="fields" responsive sticky-header="70vh">
      <template v-slot:thead-top="data">
        <b-tr>
          <b-th class="thline" colspan="2">Total a ingresar: {{total}} {{totalSymbol}} </b-th>
          <b-th class="thline" colspan="5">Fecha prevista del pago: 31/06/2020</b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>
<script>
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      socialLink: 'https://www.clickdefense.io/',
      total: 50,
      totalSymbol: this.$store.state.user.currencySymbol,
      cantidad: 10,
      items: [
        { name: 'Barcos Hidalgo', amount: `${this.cantidad}10€`, date: "05/06/2020" },
        { name: 'Floristería Loli', amount: "10€", date: "03/05/2020" },
        { name: 'Coolmod', amount: "10€", date: "21/05/2020" },
        { name: 'TechTec', amount: "10€", date: "10/04/2020" },
        { name: 'Agua Esteban', amount: "10€", date: "15/06/2020" },
      ],
      fields: [
        { key: 'name', label: 'Nombre' },
        { key: 'amount', label: 'Importe' },
        { key: 'date', label: 'Fecha de alta' },
      ],
      sharing: {
        url: 'https://www.clickdefense.io/',
        title: 'Estoy usando esta herramienta para Google Ads que me gustaría recomendarte',
        description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        twitterUser: 'ClickDefense'
      },
      networks: [
        { network: 'email', name: 'Email', icon: 'MailIcon', color: '#007bff', classicon: 'iconfeather' },
        { network: 'facebook', name: 'Facebook', icon: 'FacebookIcon', color: '#1877f2', classicon: 'iconfeather' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'LinkedinIcon', color: '#007bb5', classicon: 'iconfeather' },
        { network: 'twitter', name: 'Twitter', icon: 'TwitterIcon', color: '#1da1f2', classicon: 'iconfeather' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'MessageCircleIcon', color: '#25d366', classicon: 'iconfeather' },
      ]
    }
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      // FIN Get user
    },
    onError: function () {
      return true
    },
  },
  mounted() {
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('user-currencyName', (currencyName) => {
      this.currencyName = currencyName
    })
    EventBus.$on('user-currencySymbol', (currencySymbol) => {
      this.currencySymbol = currencySymbol
    })
  },
}
</script>
<style>
.container1 {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1%;
}
.container1text {
  font-weight: bold;
}
.container1links {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  color: #ffffff;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
}
a[class^="share-network-"] .iconfeather {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 0 5px;
  flex: 1 1 0%;
  font-weight: 400;
}
.guest-table table thead tr th {
  font-size: 1rem;
}
.thline {
  background-color: #2f1b7a !important;
  color: white !important;
}
</style>