

export default {
  isAdminUser: function (dataUser) {
    if (dataUser && dataUser.length > 0) {
      for (const user of dataUser) {
        if (user === 'Admin') {
          return true
        }
      }
    }
    return false
  }
}
