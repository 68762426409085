var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "container1" }, [
        _c("div", { staticClass: "container1text" }, [
          _vm._v("Puede compartir por los siguientes medios y redes:")
        ]),
        _c(
          "div",
          { staticClass: "container1links" },
          [
            _vm._l(_vm.networks, function(network) {
              return _c(
                "ShareNetwork",
                {
                  key: network.network,
                  style: { backgroundColor: network.color },
                  attrs: {
                    network: network.network,
                    url: _vm.sharing.url,
                    title: _vm.sharing.title,
                    description: _vm.sharing.description,
                    quote: _vm.sharing.quote,
                    hashtags: _vm.sharing.hashtags,
                    twitterUser: _vm.sharing.twitterUser
                  }
                },
                [
                  _c(
                    "i",
                    { class: network.classicon },
                    [_c("feather-icon", { attrs: { icon: network.icon } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(network.name))])
                ]
              )
            }),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.sharing.url,
                    expression: "sharing.url",
                    arg: "copy"
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:error",
                    value: _vm.onError,
                    expression: "onError",
                    arg: "error"
                  }
                ],
                staticClass: "share-network-copiar"
              },
              [
                _c(
                  "i",
                  { staticClass: "iconfeather" },
                  [_c("feather-icon", { attrs: { icon: "CopyIcon" } })],
                  1
                ),
                _c("span", [_vm._v("Copiar")])
              ]
            )
          ],
          2
        )
      ]),
      _c("b-table", {
        staticClass: "guest-table",
        attrs: {
          items: _vm.items,
          fields: _vm.fields,
          responsive: "",
          "sticky-header": "70vh"
        },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function(data) {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      { staticClass: "thline", attrs: { colspan: "2" } },
                      [
                        _vm._v(
                          "Total a ingresar: " +
                            _vm._s(_vm.total) +
                            " " +
                            _vm._s(_vm.totalSymbol) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "b-th",
                      { staticClass: "thline", attrs: { colspan: "5" } },
                      [_vm._v("Fecha prevista del pago: 31/06/2020")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }